import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Typography, message, Dropdown } from 'antd';
import {
  EditOutlined,
  FileDoneOutlined,
  PlusOutlined,
  ShareAltOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Project } from '../../helper/type';
import { useJobService } from '../../service/job.service';
import { setProject } from '../../store/hirer.slice';
import { RootState } from '../../store/store';

import './ProjectActionDropdown.scss';

const { Text } = Typography;

type Props = {
  project: Project;
  handleShare: () => void;
  className?: string;
  itemClassName?: string;
  overlayClassName?: string;
  children?: React.ReactNode;
};

const ProjectActionDropdown: FC<Props> = ({
  project,
  handleShare,
  className,
  itemClassName = 'action-button',
  overlayClassName = 'project-action-dropdown',
  children,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const jobService = useJobService();
  const { companyProfile } = useSelector((state: RootState) => state.hirer);

  const handlePublish = async () => {
    const result = await jobService.publishProject(project.id, true);
    dispatch(setProject(result));
  };

  const handleUnpublish = async () => {
    try {
      const result = await jobService.publishProject(project.id, false);
      dispatch(setProject(result));
    } catch (err) {
      const error = err as AxiosError;
      const errorData = error.response?.data;
      if (errorData?.code === 'TIME_LIMIT') {
        message.warn(errorData?.message, 10);
      }
    }
  };

  return (
    <Dropdown
      className={className}
      overlayClassName={overlayClassName}
      menu={{
        items: [
          project.isActive === 'active'
            ? {
                label: <Text>Unpublish</Text>,
                icon: <StopOutlined />,
                onClick: handleUnpublish,
                key: 'unpublish',
                className: itemClassName,
              }
            : {
                label: <Text>Publish</Text>,
                icon: <FileDoneOutlined />,
                onClick: handlePublish,
                key: 'publish',
                className: itemClassName,
                disabled: companyProfile.approvalStatus !== 'approved',
              },
          ...(project.closingDate > new Date().toISOString()
            ? [
                {
                  label: <Text>Edit Project</Text>,
                  icon: <EditOutlined />,
                  onClick: () =>
                    history.push(`/hirer/edit/project/${project.id}`),
                  key: 'edit',
                  className: itemClassName,
                },
                {
                  label: <Text>Add New Role</Text>,
                  icon: <PlusOutlined />,
                  onClick: () =>
                    history.push(`/hirer/project/${project.id}/role`),
                  key: 'add-new-role',
                  className: itemClassName,
                },
              ]
            : []),
          {
            label: <Text>Share</Text>,
            icon: <ShareAltOutlined />,
            onClick: handleShare,
            key: 'share',
            className: itemClassName,
          },
        ],
      }}
      trigger={['click']}
    >
      {children}
    </Dropdown>
  );
};

export default ProjectActionDropdown;
